import React from 'react'

import { Button, Stack, Typography } from '@mui/material'

import { BulletList } from '@/components/ProductDetailsPage/BulletList'
import { ReadingGrid } from '@/components/ReadingGrid'
import { useProductDetailAnalyticsTracking } from '@/utils/frontend/analytics/useProductDetailAnalyticsTracking'
import { useT } from '@/utils/frontend/useT'

type SafetyDataSheetsProps = {
  sdsLinkout: string
}

const dataCy = 'SafetyDataSheets'

const SafetyDataSheets = (props: SafetyDataSheetsProps) => {
  const { sdsLinkout } = props
  const { trackClickEvent } = useProductDetailAnalyticsTracking()

  const { t } = useT({ keyPrefix: 'product.documents-section.SDS-section' })

  return (
    <Stack spacing={2}>
      <Typography variant="h6" data-cy={`${dataCy}-title`}>
        {t('title')}
      </Typography>
      <ReadingGrid dataCy={dataCy}>
        <BulletList items={[t('description')]} data-cy={`${dataCy}-description`} />
      </ReadingGrid>

      <Button
        href={sdsLinkout}
        target="_blank"
        onClick={() => trackClickEvent(t('action'), sdsLinkout, 'external_cta_click')}
        sx={{
          width: {
            lg: 'fit-content',
          },
        }}
        data-cy={`${dataCy}-action`}
      >
        {t('action')}
      </Button>
    </Stack>
  )
}

export default SafetyDataSheets
